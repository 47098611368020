import React from "react";
import "./styles.css";

const ReallyMovingLayout = ({ children }) => {
  if (process.env.REACT_APP_MODE !== "prod-reallymoving")
    return <>{children}</>;

  let isMobile = window.innerWidth <= 844;

  return (
    <>
      <header className="menu-header">
        <div className="menu-inner">
          <div className="left">
            <a href="https://www.reallymoving.com/">
              <img
                className={
                  isMobile ? "chapperone-logo-mobile" : "reallymoving-logo"
                }
                src="https://www.reallymoving.com/images/logo/rm-logo-vector-svg.svg"
                alt="ReallyMoving"
              />
            </a>
            {isMobile && (
              <a
                href="https://app.moveinout.co.uk/getStarted"
                className="chapperone-logo-wrapper"
              >
                <img
                  className="st-navbar-icon chapperone-logo-mobile"
                  src={require("../../Assets/images/Moveinout-logo.png")}
                  alt="MoveInOut"
                />
              </a>
            )}
          </div>
          <div className="right">
            {!isMobile && (
              <>
                <nav className="menu-nav">
                  <ul className="menu-list">
                    <li>
                      <a
                        href="https://www.reallymoving.com/about-us"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        About us
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.reallymoving.com/contact-us"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Contact us
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.reallymoving.com/become-a-partner"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Join us
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://admin.reallymoving.com/log-in"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Partner login
                      </a>
                    </li>
                  </ul>
                </nav>
                <button
                  className="menu-button"
                  onClick={() =>
                    window.open(
                      "https://www.reallymoving.com/retrieve-your-quotes",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  Retrieve quotes
                </button>
              </>
            )}
          </div>
        </div>
      </header>

      {children}
    </>
  );
};

export default ReallyMovingLayout;
