import Axios from "axios";

import history from "../../Routes/history";

import * as AlertUtils from "../../Utils/alertUtils";
import * as LocalStorageUtils from "../../Utils/localStorageUtils";
import { setLoading } from "../../slices/App/reducer";

export default function SigninAPI(email, password) {
  return (dispatch) => {
    dispatch(setLoading(true));
    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/signin`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email: email,
        password: password,
      },
    })
      .then((response) => {
        dispatch(setLoading(false));
        LocalStorageUtils.SetAuthToken(response.data.webToken);
        // if(response?.data?.data?.yotiVerified)
        // {
        history.push("/dashboard");
        // }
        // else{
        //   history.push("/verify-yoti");
        // }
      })
      .catch((err) => {
        // console.log("error", err.response.data.message);
        AlertUtils.ErrorAlert(err);
        dispatch(setLoading(false));
      });
  };
}
