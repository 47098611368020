import React, { useEffect, useState } from "react";
import Layout from "../../ReusableComponents/Layout";
import "./style.css";

const FAQs = () => {
  const [answer1, setAnswer1] = useState(false);
  const [answer2, setAnswer2] = useState(false);
  const [answer5, setAnswer5] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  document.title="Moveinout | FAQ"

  return (
    <Layout>
      <div class="FAQ">
        <h3 class="text-center FAQHeading">Frequently Asked Questions</h3>
        <div class="d-flex flex-column align-items-center FAQItems">
          <div
            class="d-flex align-items-center pl-3 FAQItem"
            onClick={() => setAnswer1(!answer1)}
          >
            What information do i need to change my address?
            <span
              className={`${answer1 ? "FAQItemImageActive" : "FAQItemImage"}`}
            >
              <img
                src={require("../../Assets/images/qa-arrow.svg")}
                class="FAQImg"
                alt="arrow"
              />
            </span>
          </div>
          {answer1 && (
            <div className="FAQItemAnswer">
              To use Moveinout, you will need:
              <br />
              - Your name and contact information
              <br />
              - Your new and old address
              <br />
              - The date of your move
              <br />
              Some service providers will require additional information. This
              information allows organisations to find your records.
            </div>
          )}
        </div>

        <div class="d-flex flex-column align-items-center FAQItems">
          <div
            class="d-flex align-items-center pl-3 FAQItem"
            onClick={() => setAnswer2(!answer2)}
          >
            I am not moving for a while, should I wait to change my address?
            <span
              className={`${answer2 ? "FAQItemImageActive" : "FAQItemImage"}`}
            >
              <img
                src={require("../../Assets/images/qa-arrow.svg")}
                class="FAQImg"
                alt="arrow"
              />
            </span>
          </div>
          {answer2 && (
            <div className="FAQItemAnswer">
              You can send your address change requests now but we recommend
              changing your address 3 to 4 weeks before you move.
            </div>
          )}
        </div>



        <div class="d-flex flex-column align-items-center FAQItems">
          <div
            class="d-flex align-items-center pl-3 FAQItem"
            onClick={() => setAnswer5(!answer5)}
          >
            Do I have to notify all of my service providers at once?
            <span
              className={`${answer5 ? "FAQItemImageActive" : "FAQItemImage"}`}
            >
              <img
                src={require("../../Assets/images/qa-arrow.svg")}
                class="FAQImg"
                alt="arrow"
              />
            </span>
          </div>
          {answer5 && (
            <div className="FAQItemAnswer">
              No, you can access your personalised dashboard at anytime and
              notify organisations as you require.
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default FAQs;
