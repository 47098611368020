import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import ChangePassword from "../../Pages/ChangePassword";
import ResetPassword from "../../Pages/ResetPassword";
import Sigin from "../../Pages/SignIn";
import { TokenValidation } from "../../Utils/authValidationUtils";
import "./style.css";

const Navbar = (props) => {
  const [loginShow, setLoginShow] = useState(false);
  const [resetPassShow, setResetPassShow] = useState(false);
  const [changePass, setchangePass] = useState(false);

  useEffect(() => {
    // console.log("token we are getting",props.match.params.token);
    if (props.match.params.token) {
      setchangePass(true);
    }
  }, []);

  const { dashboard } = props;

  const onLogout = () => {
    localStorage.clear();
    // props.history.push("/");
    window.location.replace("https://www.moveinout.co.uk/");
  };
  const onLogoClick = () => {
    props.history.push("/");
    window.scroll(0, 0);
  };

  return (
    <nav
      className="  mn-navbar-new "
      style={
        props.loginPage ? { backgroundColor: "rgba(249, 249, 249, 0.7)" } : {}
      }
    >
      {loginShow && (
        <Sigin onCross={setLoginShow} resetPass={setResetPassShow} />
      )}
      {resetPassShow && <ResetPassword onCross={setResetPassShow} />}
      {changePass && <ChangePassword onCross={setchangePass} />}
      {/* <div className="mn-navbar-mobile-div"></div> */}
      <div className="d-flex align-items-center">
        <img
          className="navbar-brand mn-navbar-icon"
          alt="MoveInOut"
          onClick={onLogoClick}
          src={require("../../Assets/images/Moveinout-logo.png")}
          // src={require("../../Assets/images/Moveinout-Logo.svg")}
        />
        {/* <div className="mn-navbar-Links">
          <Link className="mn-navbar-Link" to="/FAQ">
            FAQs
          </Link>
          <Link className="mn-navbar-Link" to="/blogs">
            Blog
          </Link>
        </div> */}
      </div>
      {!props.noLoginButton && (
        <div className="ml-auto">
          {dashboard || TokenValidation() ? (
            <div className="mn-navbar-dashboard-wrapper">
              {window.location.pathname !== "/dashboard" && (
                <Link
                  to="/dashboard"
                  className="mn-navbar-Link"
                  style={{ color: "#000" }}
                >
                  Dashboard
                </Link>
              )}
              <div className="logout-option-navbar" onClick={() => onLogout()}>
                <img
                  className="login-icon pb-1"
                  alt="LogIn"
                  src={require("../../Assets/images/login-icon.svg")}
                />
                <span className="mn-navbar-login">Logout</span>
              </div>
            </div>
          ) : (
            <div onClick={() => setLoginShow(true)}>
              <img
                className="login-icon pb-1"
                alt="LogIn"
                src={require("../../Assets/images/login-icon.svg")}
              />
              <span className="mn-navbar-login">Login</span>
            </div>
          )}
        </div>
      )}
    </nav>
  );
};

export default withRouter(Navbar);
