import Axios from "axios";

import history from "../../Routes/history";

import * as AlertUtils from "../../Utils/alertUtils";
import { setLoading } from "../../slices/App/reducer";

export default function ChangePasswordAPI(password,token) {
  return (dispatch) => {
    dispatch(setLoading(true));
    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/changePassword`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        token:token,
        password:password
      },
    })
      .then((response) => {
        dispatch(setLoading(false));
        // console.log(response);

        AlertUtils.SuccessCustomAlert("Password Successfully Changed");
        history.push('/login');
        // dispatch({
        //   type: "signin",
        //   payload: response.data,
        // });
      })
      .catch((err) => {
        // console.log("error", err.response.data.message);
        AlertUtils.ErrorAlert(err);
        dispatch(setLoading(false));
      });
  };
}
