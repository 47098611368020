import * as LocalStorageUtils from "./localStorageUtils";
import * as AlertUtils from "./alertUtils";
import jwt from "jsonwebtoken";

export const TokenValidation=()=>{
    const token = LocalStorageUtils.GetAuthToken();
    // console.log("token we are getting",token)
    if (token) {
      var decodedToken = jwt.decode(token, { complete: true });
    //   console.log(decodedToken);
      const expiryDate = decodedToken.payload.exp * 1000;
    //   console.log("our time vs Expir",Date.now(),"hello",decodedToken.payload.exp * 1000 );
    //   console.log("our time vs Expir",new Date(Date.now()),"hello",new Date(decodedToken.payload.exp * 1000) );
      if (expiryDate < Date.now() ){
          localStorage.clear();
        AlertUtils.ErrorCustomAlert("Token Expired ! Please Login Again");
        window.location.reload();
            return false;
      }
      else{
          return true;
    //   }
    }}
    else{
            return false;
    }
}
export const getLoginAccountID=()=>{
    const token = LocalStorageUtils.GetAuthToken();
    if (token) {
      var decodedToken = jwt.decode(token, { complete: true });
      // console.log(decodedToken.payload._id);
      return decodedToken.payload._id
    }
    else {
        return false
    }
}