import Swal from 'sweetalert2';

export const ErrorAlert=(err)=> Swal.fire({
    icon: 'error',
    // title:`<span style="color:#3A4CEA">Error<span>`,
    text:`${err&&err.response&&err.response.data&&err.response.data.message?err.response.data.message.toLocaleUpperCase():"Some thing Went Wrong ! Contact to Support"}`,
    background:"#fff",
    confirmButtonColor:"#3A4CEA",
    iconColor:"#3A4CEA"
  })

  export const SuccessAlert=(response)=> Swal.fire({
    icon: 'success',
    // title:`<span style="color:#3A4CEA">Success<span>`,
    text:`${response.data.message.toLocaleUpperCase()}`,
    background:"#fff",
    confirmButtonColor:"#3A4CEA",
    iconColor:"#3A4CEA"
  })

  export const SuccessCustomAlert=(messsage)=> Swal.fire({
    icon: 'success',
    // title:`<span style="color:#3A4CEA">Success<span>`,
    text:`${messsage}`,
    confirmButtonColor:"#3A4CEA",
    iconColor:"#3A4CEA"
  })

  export const InfoCustomAlert=(messsage)=> Swal.fire({
    icon: 'info',
    // title:`<span style="color:#3A4CEA">Success<span>`,
    text:`${messsage}`,
    confirmButtonColor:"#3A4CEA",
    iconColor:"#3A4CEA"
  })

  export const ErrorCustomAlert=(messsage)=> Swal.fire({
    icon: 'error',
    // title:`<span style="color:red">Error<span>`,
    text:`${messsage}`,
    confirmButtonColor:"#3A4CEA",
    iconColor:"#3A4CEA"
  })