import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
};

const AppSlice = createSlice({
  name: "App",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const { setLoading } = AppSlice.actions;

export default AppSlice.reducer;
