import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import MetaTags from "react-meta-tags";
import { ReactTitle } from "react-meta-tags";
import "./style.css";

import ResetPasswordAPI from "../../Redux/APIs/resetPassword";

const ResetPassword = (props) => {
  const { onCross } = props;

  const [email, setEmail] = useState("");

  const reset = (e) => {
    e.preventDefault();
    props.ResetPasswordAPI(email, onCross);
  };
  document.title="Moveinout | Reset Password"

  return (
    <>
      <MetaTags>
        <ReactTitle title="Moveinout | Change of address notifications" />
      </MetaTags>
      <div className="popup-page d-flex justify-content-center align-items-center">
        <form onSubmit={reset} className="popup-box px-5 d-flex flex-column">
          <h2 className="popup-heading mt-4 text-center">
            Reset your password
          </h2>
          {/* <img
     src={require("../../Assets/images/Close-Icon.svg")}
     alt="Close"
     className="popup-close ml-auto"
     onClick={()=>onCross(false)}
    /> */}
          <p className="popup-para text-center px-3">
            Please enter your registered email address below to receive a link
            to set your password.
          </p>
          <div className="popup-input-display mt-4">
            <input
              type="email"
              className="popup-input"
              required
              value={email}
              onChange={(e) => setEmail(e.target?.value?.toLowerCase())}
            />
            <span className="popup-floating-label">Email address</span>
          </div>
          <button
            type="submit"
            className="popup-login-box popup-login-box-4 mt-4 d-flex justify-content-center align-items-center"
          >
            <p className="popup-login-box-text py-0 my-0">Reset Password</p>
          </button>
          <br />
          <br />
        </form>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = {
  ResetPasswordAPI,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ResetPassword);
