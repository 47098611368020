import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  user: {},
  email: "",
  email2: "",
  userId: "",
  initialCost: 0,
  moveInfo: "",
  previousMovinfoCompaniesLength: 0,
  guestId: "",
};

const UserSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    setEmail2(state, action) {
      state.email2 = action.payload;
    },
    setUserId(state, action) {
      state.userId = action.payload;
    },
    setInitialCost(state, action) {
      state.initialCost = action.payload;
    },
    setMoveInfo(state, action) {
      state.moveInfo = action.payload;
    },
    setPreviousMovinfoCompaniesLength(state, action) {
      state.previousMovinfoCompaniesLength = action.payload;
    },
    setGuestId(state, action) {
      state.guestId = action.payload;
    },
  },
});

export const {
  setUser,
  setEmail,
  setEmail2,
  setUserId,
  setInitialCost,
  setMoveInfo,
  setPreviousMovinfoCompaniesLength,
  setGuestId
} = UserSlice.actions;

export default UserSlice.reducer;
