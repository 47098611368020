import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import "./style.css";

import ChangePasswordAPI from "../../Redux/APIs/changePassword";

const ChangePassword = (props) => {
  const { onCross } = props;

  const [passwordShow, setPasswordShow] = useState(false);
  const [cPasswordShow, setCPasswordShow] = useState(false);
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [notMatch, setNotMatch] = useState(false);
  const reset = (e) => {
    e.preventDefault();
    if (password === cPassword) {
      props.ChangePasswordAPI(password,props.match.params.token);
    }
    else {
      return setNotMatch(true);
    }
  }

  document.title="Moveinout | Change Password"

  return (
    <div className="popup-page d-flex justify-content-center align-items-center">
      <form onSubmit={reset} className="popup-box px-5 d-flex flex-column">
        <h2 className="popup-heading mt-4 text-center">Reset Password</h2>
        {/* <img
          src={require("../../Assets/images/Close-Icon.svg")}
          alt="Close"
          className="popup-close ml-auto"
          onClick={() => onCross(false)}
        /> */}
        <p className="popup-para text-center px-3">
          Please enter your new password.
    </p>
        <div className="popup-input-display mt-4">
          <input required type={passwordShow ? "text" : "password"} value={password} onChange={e => setPassword(e.target.value)} className="popup-input" />
          <span className="popup-floating-label">New Password</span>
          <span className="popup-floating-password" onClick={()=>setPasswordShow(!passwordShow)}>{passwordShow?"Hide":"Show"}</span>
        </div>
        <div className="popup-input-display mt-4">
          <input type={cPasswordShow ? "text" : "password"} value={cPassword} onChange={e => setCPassword(e.target.value)} className="popup-input" required />
          <span className="popup-floating-label">Retype New Password</span>
          <span className="popup-floating-password" onClick={() => setCPasswordShow(!cPasswordShow)}>{cPasswordShow ? "Hide" : "Show"}</span>
        </div>
        {notMatch && <span className="pt-2 pl-2" style={{ color: "red" }}>Passwords not matched</span>}
        <button
          type="submit"
          className="popup-login-box popup-login-box-4 mt-4 d-flex justify-content-center align-items-center"
        >
          <p className="popup-login-box-text py-0 my-0">Reset Password</p>
        </button>
        <br />
        <br />
      </form>
    </div>);
}
const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = {
  ChangePasswordAPI
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ChangePassword);
