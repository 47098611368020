import React from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import NewFooter from "../NewFooter";
const Layout = (props) => {
  return (
    <>
      <Navbar noLoginButton={props.noLoginButton} />
      {props.children}
      {/* <Footer /> */}
      <NewFooter />
    </>
  );
};

export default Layout;
