import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  services: [],
  servicesData: [],
  initialServices: [],
};

const ServicesSlice = createSlice({
  name: "Services",
  initialState,
  reducers: {
    setServices(state, action) {
      state.services = action.payload;
    },
    setServicesData(state, action) {
      state.servicesData = action.payload;
    },
    setInitialServices(state, action) {
      state.initialServices=action.payload;
    }
  },
});

export const {
  setServices,
  setServicesData,
  setInitialServices
} = ServicesSlice.actions;

export default ServicesSlice.reducer;
