import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  moveDate: "",
  moveAgainDate: "",
};

const MoveDateSlice = createSlice({
  name: "MoveDate",
  initialState,
  reducers: {
    setMoveDate(state, action) {
      state.moveDate = action.payload;
    },
    setMoveAgainDate(state, action) {
      state.moveAgainDate = action.payload
    }
  },
});

export const {
  setMoveDate,
  setMoveAgainDate
} = MoveDateSlice.actions;

export default MoveDateSlice.reducer;
