import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  newAddress: "",
  oldAddress: "",
  newAddressObj: {},
  oldAddressObj: {},
};

const AddressSlice = createSlice({
  name: "Address",
  initialState,
  reducers: {
    setNewAddress(state, action) {
      state.newAddress = action.payload;
    },
    setOldAddress(state, action) {
      state.oldAddress = action.payload;
    },
    setNewAddressObj(state, action) {
      state.newAddressObj = action.payload;
    },
    setOldAddressObj(state, action) {
      state.oldAddressObj = action.payload;
    },
  },
});

export const {
  setNewAddress,
  setOldAddress,
  setNewAddressObj,
  setOldAddressObj,
} = AddressSlice.actions;

export default AddressSlice.reducer;
