import Axios from "axios";

import history from "../../Routes/history";

import * as AlertUtils from "../../Utils/alertUtils";
import { setLoading } from "../../slices/App/reducer";

export default function resetPasswordAPI(email, setPopupClose) {
  return (dispatch) => {
    dispatch(setLoading(true));
    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/resetPassword`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email: email,
      },
    })
      .then((response) => {
        dispatch(setLoading(false));
        // console.log(response);
        // setPopupClose(false);
        history.push("/login");
        AlertUtils.SuccessCustomAlert(
          "Reset password link is sent to your registered email address."
        );
        // dispatch({
        //   type: "signin",
        //   payload: response.data,
        // });
      })
      .catch((err) => {
        // console.log("error", err.response.data.message);
        AlertUtils.ErrorCustomAlert(`This email address does not appear to be registered.
        Please check you have entered it correctly`);
        dispatch(setLoading(false));
      });
  };
}
