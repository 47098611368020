import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import MetaTags from "react-meta-tags";
import { ReactTitle } from "react-meta-tags";
import "./style.css";

import SigninAPI from "../../Redux/APIs/signin";
import { Link } from "react-router-dom";
import Navbar from "../../ReusableComponents/Navbar";

const Sigin = (props) => {
  const { onCross, resetPass } = props;

  const [passwordShow, setPasswordShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const signin = (e) => {
    e.preventDefault();
    props.SigninAPI(email, password);
  };

  document.title="Moveinout | Login"


  return (
    <>
      <MetaTags>
        <ReactTitle title="User login page" />
        <meta
          name="description"
          content="Log in to your Moveinout account to notify your service providers of your new address."
        />
      </MetaTags>
      <Navbar loginPage noLoginButton />
      <div className="popup-page d-flex justify-content-center align-items-center">
        <form onSubmit={signin} className="popup-box1 px-5 " autocomplete="off">
          <h2 className="popup-heading mt-4 text-center">Welcome Back</h2>

          <p className="popup-para text-center px-3">
            Pick up where you left off.
          </p>

          <div className="popup-input-display mt-4">
            <input
              type="text"
              className="popup-input"
              autocomplete="off"
              required
              value={email}
              onChange={(e) => setEmail(e.target?.value?.toLowerCase())}
            />
            <span className="popup-floating-label">Email address</span>
          </div>
          <div className="popup-input-display mt-4">
            <input
              required
              type={passwordShow ? "text" : "password"}
              autocomplete="off"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="popup-input"
            />
            <span className="popup-floating-label">Password</span>
            <span
              className="popup-floating-password"
              onClick={() => setPasswordShow(!passwordShow)}
            >
              {passwordShow ? "Hide" : "Show"}
            </span>
          </div>
          <Link to="/reset-password">
            <div className="pt-3 ml-2 forgetPassword-text">
              <b>I've forgotten my password</b>
            </div>
          </Link>
          <Link to="/getStarted">
            <div className="pt-3 signup-text">
              <b>Don't have an account ?</b>
            </div>
          </Link>
          <button
            type="submit"
            className="popup-login-box popup-login-box-4 mt-4 d-flex justify-content-center align-items-center"
          >
            <p className="popup-login-box-text py-0 my-0">Login</p>
          </button>
          <br />
          <br />
        </form>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = {
  SigninAPI,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Sigin);
