import React from 'react';
import Loader from 'react-loader-spinner'

const style = {
  margin: 0,
  padding: 0,
  minHeight: "100vh",
  minWidth: "100vw",
  position: "fixed",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  left: 0,
  top: 0,
  zIndex: 50,
  backgroundColor: "rgba(0,0,0,0.4)",
};

const WaitingLoader = () => {
  return (
    <div style={style}>
      <Loader
         type="Puff"
         color="#8888"
         height={100}
         width={100}
      />
    </div>
  );
};

export default WaitingLoader;
